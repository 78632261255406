import React,{useState,useEffect} from 'react'
import './App.css'
import { Link } from 'react-router-dom'
import { Sidebar } from './component'
import axios from 'axios'
import swal from 'sweetalert';
import  { Redirect } from 'react-router-dom'
// import {UserProfile,PrivateRoutes} from './utils/PrivateRoutes';
import { BrowserRouter,Route} from 'react-router-dom';
import { server_addr } from './utils/PrivateRoutes'

function LoginPage() {
    const [values,setValues]=useState({
        name:'guest',
        password:'guest',
})

const handleInput=(event)=>{
    setValues(prev=>({...prev,[event.target.name]:[event.target.value]}))
}

const handleSubmit=(event)=>{
      event.preventDefault();
      axios.post(`https://${server_addr}/Login`,[values.name,values.password],{
    withCredentials: true
}).then((res) => {
        // Update the 'obj' property by creating a new array
        // console.log(res.data)
        if(res.data.length!=0){
            window.location="/"
        }
        else{
            swal("Oops!", "Username or Password not Found!", "error");
        }

      }).catch(err=>{alert("User of this ID not found")})

}

const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

const handleResize = () => {
  setIsMobileView(window.innerWidth <= 600);
};

useEffect(() => {
  // Set up event listener for window resize
  window.addEventListener('resize', handleResize);

  // Cleanup function to remove the event listener
  return () => window.removeEventListener('resize', handleResize);
}, [isMobileView]);

    if(isMobileView){
    return (
        <body class="body">
          
            <div class="logincont">
            <Sidebar/>
            <form action="" onSubmit={handleSubmit} class="form-style" >
            <h2>Sign in to Movies</h2>
                <p>
                    <label>Username</label><br/>
                    <input type="text" name="name" onChange={handleInput} value={values.name} required />
                </p>
                <p>
                    <label>Password</label>
                    <br/>
                    <input type="password" name="password" onChange={handleInput}  value={values.password} required />
                </p>
                <p>
                    <button id="sub_btn" type="submit">Login</button>
                </p>

                <footer>
                {/* <p><Link to="/Register">Create new account</Link>.</p> */}
            </footer>
            </form>
            </div>
            
        </body>
    );
    }
    else{

        return (
            <body class="body">
              <Sidebar/>
                <div class="logincont">
                
                <form action="" onSubmit={handleSubmit} class="form-style" >
                <h2>Sign in to Movies</h2>
                    <p>
                        <label>Username</label><br/>
                        <input type="text" name="name" onChange={handleInput} value={values.name} required />
                    </p>
                    <p>
                        <label>Password</label>
                        <br/>
                        <input type="password" name="password" onChange={handleInput}  value={values.password} required />
                    </p>
                    <p>
                        <button id="sub_btn" type="submit">Login</button>
                    </p>
    
                    <footer>
                    {/* <p><Link to="/Register">Create new account</Link>.</p> */}
                </footer>
                </form>
                </div>
                
            </body>
        );

    }
}

export default LoginPage;