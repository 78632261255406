import './App.css';
import {Moviecomp,Sidebar} from './component';
import axios from 'axios';
import { server_addr } from './utils/PrivateRoutes';
import React,{useState,useEffect} from 'react';

let obj=await axios.post(`https://${server_addr}/Home`,["Horror"]).then(res=>res.data);


export default function Horror() {

  const picpath="https://www.themoviedb.org/t/p/w300_and_h450_bestv2";

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 600);
  };

  useEffect(() => {
    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileView]);
  

  if(isMobileView){
    return (
      <body class="body">
      <div class="App-header">
      <Sidebar/>
        {obj.map((i)=><Moviecomp piclink={picpath+i["Picture"]} overview={i["Overview"]} voteavg={i["Vote_Avg"]} id={i["movie_id"]} 
        name={i["Title"]}/>)}
       
      </div>
      </body>
    );
  }
  else{
    return(
    <body class="body">
    <Sidebar/>
    <div class="App-header">
      {obj.map((i)=><Moviecomp piclink={picpath+i["Picture"]} overview={i["Overview"]} voteavg={i["Vote_Avg"]} id={i["movie_id"]} 
      name={i["Title"]}/>)}
     
    </div>
    </body>
    );
  }
  }
  